<template>
  <activity_change></activity_change>
</template>

<script>
import activity_change from "./activity_change";

export default {
  name: "add_activity",
  components:{
    activity_change
  },
  data() {
    return {

    };
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
